
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { HumanCard, WatchList } from '@/api';
import NButton from '@/uikit/buttons/NButton.vue';
import NForm, { FormValidatorNames } from '@/uikit/forms/NForm.vue';
import { getStringLengthValidator } from '@/uikit/forms/validators';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import enrichCards, { EnrichedCard } from '@/components/cards/enrich-cards';
import { monitoringModule } from './MonitoringModule';
import CardItemRow from '@/components/cards/CardItemRow.vue';

@Options({
  name: 'CardMonitoringAddDialog',
  components: {
    NButton,
    NForm,
    NModalWindow,
    CardItemRow
  },
  emits: ['close']
})
export default class CardMonitoringAddDialog extends Vue {
  @Prop({ type: Array, required: true })
  readonly items: HumanCard[] = [];

  @Prop({ type: Array, required: true })
  readonly watchLists: WatchList[] = [];

  enrichedItems: EnrichedCard[] = [];
  loading: boolean = false;

  protected fields = {
    reason: ''
  };

  protected layout = [
    {
      path: 'reason',
      classes: 'label-m n-form-w-4 n-form-pad-10',
      i18n_label: 'remote_monitoring.monitoring_reason',
      component: NTextareaAutosize,
      props: { minRows: 3, i18n_placeholder: 'remote_monitoring.monitoring_reason_placeholder' },
      validators: [{ name: FormValidatorNames.Required }, getStringLengthValidator({ required: true, min: 3, max: 512 })]
    }
  ];

  async createMonitorings() {
    if (!this.$refs.form.validate()) {
      this.$refs.form.displayErrors();
      return;
    }
    if (this.loading) return;

    try {
      this.loading = true;

      for (let item of this.items) {
        await monitoringModule.create({ card: item.id, reason: this.fields.reason });
        await monitoringModule.getItemsByCard(item.id, true);
      }

      for (let item of this.watchLists) {
        await monitoringModule.create({ watch_list: item.id, reason: this.fields.reason });
        await monitoringModule.getItemsByWatchList(item.id, true);
      }
    } catch (e) {
      console.warn('[monitoring] creating error: ', e);
    } finally {
      this.loading = false;
    }

    this.$emit('close');
  }

  async enrichItems() {
    this.enrichedItems = await enrichCards(this.items);
  }

  mounted() {
    this.enrichedItems = [];
    this.enrichItems();
    this.$refs.form?.focus();
  }
}
