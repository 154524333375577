import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "cards-monitoring-add-dialog__form-wrapper" }
const _hoisted_2 = { class: "cards-monitoring-add-dialog__form" }
const _hoisted_3 = { class: "cards-monitoring-add-dialog__watch-lists" }
const _hoisted_4 = { class: "cards-monitoring-add-dialog__cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardItemRow = _resolveComponent("CardItemRow")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    layout: "",
    "custom-size": "600px"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.watchLists, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id
              }, [
                _createTextVNode(_toDisplayString(_ctx.$t('common.watch_list', 'f')) + ": ", 1),
                _createElementVNode("b", null, _toDisplayString(item.name), 1)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enrichedItems, (item) => {
              return (_openBlock(), _createBlock(_component_CardItemRow, {
                key: item.id,
                item: item,
                type: "humans",
                closeable: false
              }, null, 8, ["item"]))
            }), 128))
          ]),
          _createVNode(_component_NForm, {
            model: _ctx.fields,
            layout: _ctx.layout,
            ref: "form"
          }, null, 8, ["model", "layout"]),
          _createVNode(_component_NButton, {
            onClick: _ctx.createMonitorings,
            loading: _ctx.loading,
            class: "cards-monitoring-add-dialog__form-submit",
            size: "big"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('remote_monitoring.add_to_monitoring')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "loading"])
        ])
      ])
    ]),
    _: 1
  }))
}